@import "../../../assets/scss/colors.scss";

.tab-nav-list-holder {
  button {
    background-color: transparent;
    font-size: 20px;
    line-height: 24px;
    font-family: BookSizeFont;
    color: rgba(0, 0, 0, 0.5);
    svg, path {
      stroke: rgba(0, 0, 0, 0.5);
    }
    border-bottom: solid 1px (0, 0, 0, 0.5);
    border-radius: 0px;
    padding: 12px 20px;
    width: auto;
    &.active {
      color: $blue1;
      border-bottom: solid 1px $blue1;
      svg, path {
        stroke: $blue1;
      }
    }
  }
}
.tab-content-holder-outlet {
  margin-top: 30px;
  .tab-content-holder {
    display: none;
    &.active {
      display: block;
    }
  }
}