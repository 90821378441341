@import "../../../assets/scss/colors.scss";

.content-holder {
    position: absolute;
    margin-top: 60px;
    margin-bottom: 40px;
    margin-left: 80px;
    width: calc(100% - 100px);
    height: 100%;
    padding: 28px 64px;
    zoom: 0.8;
    .breadcrumb-info {
        span {
            color: $grey3;
            font-size: 16px;
            line-height: 19px;
        }
        span.parent-breadcrumb {
            color: #0f1010;
        }
    }
    .page-info {
        margin-top: 47px;
        color: $black1;
        .page-info-title {
            font-size: 32px;
            line-height: 38px;
        }
    }
    .raptor-copyright {
        text-align: center;
        width: calc(100% - 100px);
        background-color: $white1;
        z-index: 0;
        * {
            color: #534d4d;
        }
    }
    &.map-view {
        padding: 0px;
        zoom: unset;
        .breadcrumb-info {
            display: none;
        }
        .raptor-copyright {
            background-color: transparent;
            color: #534D4DE5;
        }
    }
}
