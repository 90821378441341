@import "../../assets/scss/colors.scss";

.ol-overlaycontainer-stopevent {
    z-index: 4 !important;
}

.overlay-cotainer {
    text-align: center;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    bottom: 17px;
    background: $white1 0% 0% no-repeat padding-box;
    padding: 8px 10px;
    font-size: 12px;
    color: $black1;
    p {
        font-family: LightSizeFont;
        text-align: initial;
        margin: 0px;
        white-space: nowrap;
    }
}

.overlay-cotainer #feature-additional-info::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.overlay-cotainer::before {
    content: "";
    position: absolute;
    background-color: $white1;
    bottom: -10px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
}
