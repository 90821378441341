@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.profile-section-container {
    display: flex;
    font-family: BookSizeFont;
    .language-selector {
        background: $white1 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 0;
        margin-right: 40px;
        button {
            background: $white3;
            border: 1px solid $white4;
            border-radius: 5px;

            color: $black2;
            min-width: 130px;
            max-width: 130px;
            height: 35px;
            padding: 10px 20px;
            span {
                margin: 0px;
            }
            span[class^="ReactFlagsSelect-module_selectFlag"],
            span[class^="ReactFlagsSelect-module_label"] {
                display: none;
            }
            span[class^="ReactFlagsSelect-module_selectValue"] {
                padding: 0px;
            }
            span[class^="ReactFlagsSelect-module_secondaryLabel"] {
                color: $black2;
                font-size: 16px;
            }
        }
        button::after {
            height: auto;
            width: auto;
            border: none;
        content: url("../../../assets/images/caret-2.svg");
        }
        button[aria-expanded="true"]::after {
            transform: rotate(180deg);
        }
        span[class^="ReactFlagsSelect-module_secondaryLabel"] {
            color: $black2;
            font-size: 16px;
        }
        ul[class^="ReactFlagsSelect-module_selectOptions"] {
            margin-top: 20px;
            border: 1px solid $white4;
            border-radius: 5px;
            background: #FAF9F9;
        }
    }
    .profile-menu-item-holder {
        max-height: 0px;
        overflow-y: auto;
        width: 158px;
        position: absolute;
        right: 20px;
        top: 72px;
        z-index: 3;
        background: #FAF9F9;
        margin-right: 48px;

        &.is-active {
            max-height: 950px;
            border-radius: 5px;
            border: 1px solid $white4;
        }
        .profile-menu-item {
            font-size: 14px;
            padding: 12px 14px;
            color: $black5;
        }
    }
    @include customScrollbar('.profile-menu-item-holder');
}
