@import "../../assets/scss/colors.scss";
@import "../../assets/scss/dynamic.scss";

.visualize-container {
    height: 100%;
    position: relative;
    .visualize-form-container {
        position: absolute;
        top: 0;
        z-index: 3;
        zoom: 0.8;
        // width: 420px;
        height: 100%;
        background: $white1;
        box-shadow: -20px 20px 44px 15.5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
        &.full-transition {
            @include transition4Val(max-height 0.5s ease-out, max-width 0.5s ease-out, left 0.5s linear, top 0.5s linear);
        }
        &.hide-info-tile {
            border: none;
            height: 51px;
            width: 51px;
            padding: 0px;
            cursor: pointer;
            z-index: 1;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .title-with-icon {
                display: none;
            }
            .moveable-window-container-tooltip {
                display: block;
                opacity: 0;
                max-width: 0px;
                .elipsis-title {
                    padding: 7px 8px 4px;
                    overflow: hidden;
                }
            }
    
            .Tabs {
                display: none;
            }
            .visualize-form-header-sec {
                height: 100%;
                padding: 0px;
                width: 100%;
                justify-content: center;
                .action-btn-icon {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .visualize-form-header-sec {
            padding: 33px 20px;
            display: flex;
            align-items: center;
            width: $tabDivWidth;
            background: $white1;
            justify-content: space-between;
            font-family: BoldSizeFont;
            .title-with-icon {
                font-size: 18px;
                line-height: 22px;
            }
            .action-btn-icon {
                svg {
                    cursor: pointer;
                }
            }
        }
        .raptor-select-input-container {
            padding: 16px 17px;
            .raptor-select-input-label {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 3px;
                font-family: LightSizeFont;
            }
            .waywize-react-select.__control.waywize-react-select.__control--is-focused.waywize-react-select.__control--menu-is-open {
                border: 1px solid rgba(68, 75, 83, 0.5);
                outline-color: unset;
            }
            .waywize-react-select.__control {
                border: 1px solid rgba(68, 75, 83, 0.5);

                &:focus,
                &:active {
                    border: 1px solid rgba(68, 75, 83, 0.5);
                }

                .waywize-react-select.__value-container {
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(0, 0, 0, 0.6);
                    font-family: LightSizeFont;
                }
                .waywize-react-select.__indicators .caret-down path {
                    fill: rgba(0, 0, 0, 0.6);
                }
            }

            .waywize-react-select.__menu {
                .waywize-react-select.__menu-list {
                    padding: 0px;
                    .waywize-react-select.__option {
                        height: 40px;
                        font-family: LightSizeFont;
                        font-size: 15px;
                        line-height: 18px;
                        color: rgba(0, 0, 0, 0.6);
                        border-bottom: none;
                    }
                }
            }
        }
        .tab-nav-list-holder {
            display: grid;
            grid-template-columns: auto auto;
            width: $tabDivWidth;
            background: $white1;
            button {
                font-size: 18px;
                line-height: 22px;
            }
        }
        .tab-content-holder-outlet {
            margin-top: 10px;
        }
    }
    @include customScrollbar(".visualize-form-container");
}