@import "../../../assets/scss/colors.scss";

.confirm-alert-popup-container {
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgb(8 8 8 / 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    .confirm-alert-title-sec {
        background-color: $orange4;
        width: 100%;
        display: flex;
        padding: 47px 68px;
        position: relative;

        h3 {
            font-family: BookSizeFont;
            color: $black6;
            font-size: 28px;
            margin: 0px;
            margin-left: 50px;
        }

        img {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
        }
    }
    .confirm-alert-desc-sec {
        padding: 40px 60px;
        p {
            color: #888888;
            font-family: LightSizeFont;
            font-size: 18px;
            text-align: initial;
        }
    }
    .confirm-alert-popup-message {
        width: 813px;
        max-width: 813px;
        border-radius: 5px;
        background-color: $white1;
        color: $black1;

        .button-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 60px;

            button {
                padding: 18px 56px;
                background: $white1 0% 0% no-repeat padding-box;
                cursor: pointer;
                font-family: BookSizeFont;
                font-size: 16px;
                line-height: 19px;
                border-radius: 5px;
            }
            .confirm-cancel {
                background-color: transparent;
                color: $blue1;
                border: 2px solid $blue1;
                margin-right: 30px;
            }
            .confirm-agree {
                color: $white1;
                background-color: $blue1;
                border: 2px solid $blue1;
            }
        }
    }
}
