@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

table {
    min-height: 300px;
    border-collapse: collapse;
    overflow: hidden;
    width: 100vw;
    zoom: $zoomLevel;
    border-color: transparent;
    .action-btn-view,
    .action-btn-edit,
    .action-btn-delete {
        height: 32px;
        width: 32px;
        padding: 0px;
        margin: 0 5px;
        border: none;
        background-color: transparent;
    }
    margin-bottom: 30px !important;

    .table-in-table {
        position: relative;
    }
}

.table > :not(:first-child) {
    border-top: none !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: none !important;
}
.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: none !important;
    background-color: $black3;
    cursor: pointer;
}

.multi-check-td {
    position: relative;
    .expend-icon-holder {
        position: absolute;
        right: -15px;
        top: 0;
        bottom: 0;
        height: 35px;
        width: 35px;
        margin: auto;
        transform: rotate(-90deg);
        &.expended-div {
            transform: rotate(0deg);
        }
    }
}
.table-hover > tbody > tr:hover > td:not(.multi-check-td):not(.action-btn-td) {
    color: rgba(00, 00, 00, 0.5);
}

table tr:hover td.multi-check-td {
    .raptor-checkbox-input-container {
        .raptor-checkbox-checkmark {
            border: 1px solid $blue1;
        }
    }
}

.table-bordered thead tr {
    height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    .raptor-label-input {
        margin-left: 0px;
    }
}

.table-bordered thead tr th.multi-check-th,
.table-bordered tbody tr td.multi-check-td {
    width: 110px;
    max-width: 110px;
}

.table-bordered thead tr th.multi-check-th {
    height: 70px;
    display: flex;
}

.table-bordered > :not(caption) > *,
.table-bordered > :not(caption) > * > * {
    border-width: 0px !important;
}

.table-bordered tbody tr:not(.tr-for-dd-content) {
    height: 70px;
    border-bottom: 1px solid #EEEEEE !important;
    border-bottom-width: 1px !important;
    &:last-child {
        border-bottom: none !important;
    }
}

table,
th,
td {
    border: 1px solid $grey2;
}

table {
    border: none;
    thead {
        width: 100%;
        height: 43px;
        background: transparent 0% 0% no-repeat padding-box;
        tr th {
            color: $black1;
            font-size: 20px;
            line-height: 24px;
            font-family: MediumSizeFont;
            padding: 10px 20px !important;
            vertical-align: baseline;
            text-align: left;
        }
    }
    tbody {
        tr {
            vertical-align: middle;
            &.active-rw {
                background-color: $grey1;
            }
            td {
                font-size: 20px;
                font-family: BookSizeFont;
                padding: 10px 20px !important;
                display: table-cell !important;
                text-align: left;
            }
            &:last-child {
                .action-btn-list {
                    .action-list-holder {
                        top: -35px;
                    }
                }
            }
        }
    }

    .action-btn-list {
        display: flex;
        font-family: BookSizeFont;
        position: relative;
        z-index: 2;
        .action-list-holder {
            max-height: 0px;
            overflow-y: hidden;
            width: auto;
            min-width: 130px;
            position: absolute;
            right: -25px;
            top: 20px;
            z-index: 3;
            background: #faf9f9;
            margin-right: 48px;
            filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.161));
            border-radius: 5px;

            @include transitionVal(max-height 0.1s ease-out);
            &.is-active {
                max-height: 950px;
            }
            button {
                font-size: 16px;
                line-height: 19px;
                color: $black6;
                padding: 9px 8px;
                font-family: LightSizeFont;
                background-color: $white1;
            }
        }
        @include customScrollbar(".action-list-holder");
    }
}

.download-btn-holder {
    cursor: move;
    button {
        font-family: MediumSizeFont;
        background: $white1 0% 0% no-repeat padding-box;
        border: 2px solid $blue3;
        border-radius: 42px;
        color: $blue3;
        font-size: 20px;
        cursor: pointer;
    }
}

@include customScrollbar(".childrenTable");