@import "../../../assets/scss/colors.scss";

.overlay-dialog-create-project {
  .confirm-alert-title-sec {
    background-color: $green4;
    path {
      fill: $green1;
      stroke: $green1;
    }
  }
  .confirm-alert-desc-sec {
    background-color: $green6;
  }
}
.map-and-form-holder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .project-form-container {
    width: 50%;
  }
  .map-view-window {
    width: 40%;
    position: absolute;
    right: 0;
    height: 100vh;
    .map-view-shield {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
      .map-hint-btn {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        margin: auto;
        width: 278px;
        font-size: 16px;
        line-height: 19px;
        color: $white1;
        border: solid 1px $white1;
        background-color: transparent;
      }
    }
  }
}
