@import "../../assets/scss/dynamic.scss";
@import "../../assets/scss/colors.scss";
@import "../../assets/scss/screen.scss";

.navbar-main-box {
    position: fixed;
    width: 100%;
    z-index: 4;
    zoom: 0.8;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    background-color: $blue7;

    height: 72px;
            padding-left: 12px;
            padding-right: 12px;


            .logo-section {
                cursor: pointer;
            }
            .prj-title-sec {
                color: $white1;
                font-size: 20px;
                font-family: BookSizeFont;
            }
    
            .navbar-menu-toggle {
                display: none;
                @include tablet {
                    display: block;
                }
            }
    
            #profile-section {
                margin-right: 60px;
                outline: none;
                box-shadow: none;
                .user-name-div {
                    margin-left: 10px;
                    margin-right: 15px;
                    max-width: 70px;
                    color: $white1;
                    font-size: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
}
