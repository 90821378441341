@import "../../../assets/scss/screen.scss";
@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.custom-popup-container {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0;
    transition: opacity 500ms;
    background-color: rgba(25, 24, 22, 0.5);
    visibility: visible;
    opacity: 1;
    z-index: 1;
    align-items: center;

    .custom-popup-sub-container {
        width: 600px;
        max-width: 80%;
        height: 90vh;
        background-color: $white1;
        border-radius: 5px;
    
        .custom-popup-header-section {
            position: relative;
            height: 68px;
            width: 100%;
            padding: 20px 30px;
            border-bottom: solid 1px $grey2;
    
            .custom-popup-header-title {
                font-family: MediumSizeFont;
                font-size: 20px;
                color: $black1;
            }
            .custom-popup-header-sub-title{
                color: #888888;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                border-bottom: 1px solid #BEBEBE;
                padding-bottom: 15px;
            }
            .custom-popup-header-action-tool {
    
                .svg-img-holder {
                    position: absolute;
                    right: 30px;
                    top: 15px;
                    transition: 0.5s;
                    background: transparent;
                    cursor: pointer;
                }
            }
        }
    }
    
    .custom-popup-wrapper {
        position: relative;
        overflow-y: auto;
        max-height: 90vh;
        height: 500px;
    }
    @include customScrollbar('.custom-popup-wrapper');
}