@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 113px;
  }
}

.upload-status-card-container {
  display: flex;
  zoom: 1.5;
  .progress-info-holder {
    min-width: 88px;
    &.Running {
      .uploading-label {
        color: $orange1;
      }
    }
    &.Complete {
      .uploading-label {
        color: $green1;
      }
    }
    &.Error {
      .uploading-label {
        color: $red4;
      }
    }
    .uploading-label {
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.7);
      font-family: LightSizeFont;
      margin-top: 4px;
      text-align: left;
    }
    .uploading-progress-bar-holder {
      position: relative;
      [id^=uploading-progress-val] {
        position: absolute;
        right: -30px;
        top: -1px;
        
        font-size: 10px;
        line-height: 12px;
        font-family: BookSizeFont;
        color: $grey3;
        text-align: right;
      }

      [id^=uploading-progress-holder] {
        width: 100%;
        background-color: $grey3;
        border-radius: 5px;
        [id^=uploading-progress-bar] {
          max-width: 1%;
          height: 5px;
          font-size: 17px;
          background-color: $green1;
          text-align: center;
          line-height: 28px;
          color: $white1;
          border-radius: 5px;
          @include transitionVal(max-width 0.5s ease-out);
          &.Running, &.Pending {
            background-color: $orange3;
            background-image: repeating-linear-gradient(
              90deg,
              transparent,
              transparent 40px,
              $black1 40px,
              $black1 60px
            );
        
            animation: slide 3s linear infinite;
            will-change: background-position;
          }
          &.Complete {
            background-color: $green1;
          }
          &.Error {
            background-color: $red4;
          }
        }
      }
    }
  }
}
