@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.raptor-label-textarea  {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 19px;
    textarea {
        width: 370px;
        background: $white1 0% 0% no-repeat padding-box;
        border-radius: 5px;
        margin-top: 15px;
        color: $black1;
        padding: 18px 20px;
        border: 2px solid $grey2;

        &:focus-visible,
        &:focus,
        &:active {
            border: 2px solid $blue1;
            outline-color: $blue1;
        }
        &:disabled {
            background: $grey6;
        }
    }
    .err-msg-span {
        margin-top: 12px;
        font-size: 16px;
        color: $orange1;
    }
}
