@import "../../assets/scss/colors.scss";

.not-found-container {
    flex-direction: column;
    padding-top: 100px;
    
    span {
        margin-bottom: 20px;
    }
    
    button {
        width: 300px;
        height: 47px;
        font-size: 18px;
        font-weight: bold;
        color: $white1;
        background-color: $blue1;
    }
}