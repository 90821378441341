@import './assets/scss/common.module.scss';
@import './assets/scss/colors.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .app-spin-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: MediumSizeFont;
  src: url('./assets/fonts/GothamRounded-Medium.otf');
}

@font-face {
  font-family: BoldSizeFont;
  src: url('./assets/fonts/GothamRounded-Bold.otf');
}

@font-face {
  font-family: LightSizeFont;
  src: url('./assets/fonts/GothamRounded-Light.otf');
}

@font-face {
  font-family: MediumItalicSizeFont;
  src: url('./assets/fonts/Gotham\ Rounded\ Medium\ Italic.otf');
}

@font-face {
  font-family: BoldItalicSizeFont;
  src: url('./assets/fonts/Gotham\ Rounded\ Bold\ Italic.otf');
}

@font-face {
  font-family: LightItalicSizeFont;
  src: url('./assets/fonts/Gotham\ Rounded\ Light\ Italic.otf');
}

@font-face {
  font-family: BookSizeFont;
  src: url('./assets/fonts/GothamRounded-Book.otf');
}

@font-face {
  font-family: BookItalicSizeFont;
  src: url('./assets/fonts/GothamRounded-BookItalic.otf');
}

body {
  font-family: BookSizeFont;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}

#rcc-confirm-button {
  margin: 15px;
  color: $white1 !important;
  background-color: $blue1 !important;
}

.waywize-toast-container {
  top: 90px !important;
  .waywize-toast-content {
    border-radius: 5px;
    height: auto;
    color: $white1;
    font-family: BookSizeFont;
    font-size: 18px;
    line-height: 22px;
    min-height: unset;
    padding: 13px 20px 10px 20px;

    &.Toastify__toast--error {
      background: $red1 0% 0% no-repeat padding-box;
    }
    &.Toastify__toast--success {
      background: $green2 0% 0% no-repeat padding-box;
    }
    &.Toastify__toast--info {
      background: $blue5 0% 0% no-repeat padding-box;
    }
    .Toastify__toast-body {
      margin: 0px;
      padding: 0px;
    }
    .Toastify__close-button--light {
      color: $white1;
      height: 10px;
      width: 10px;
    }
  }
}