@import "../../../assets/scss/colors.scss";

.success-dialog-container {
    span {
        margin-left: 10px;
        font-size: 18px;
        line-height: 22px;
        font-family: BookSizeFont;
    }
}
