@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.project-form-container {
    width: 100%;
    flex-direction: column;
    zoom: $zoomLevel;
    margin-top: 38px;
    .d-flex {
        width: 100%;
    }
    .title-and-back-btn {
        font-family: BookSizeFont;
        color: $black1;
        display: flex;
        img, svg {
            cursor: pointer;
        }
        .title-value {
            margin-left: 14px;
            margin-right: 18px;
            font-size: 32px;
            line-height: 38px;
        }
    }
    .page-desc-sec {
        font-size: 22px;
        line-height: 26px;
        margin-top: 6px;
    }
    form {
        display: flex;
        flex-direction: column;
        .raptor-label-input {
            margin: 0px;
            input {
                width: 335px;
            }
        }

        .waywize-react-select.__control {
            width: 335px;
        }
        textarea {
            min-width: 530px;
        }
        .prj-act-btn-holder {
            margin-top: 47px;
            margin-bottom: 50px;
            button {
                font-size: 16px;
                line-height: 19px;
                font-family: BookSizeFont;
                cursor: pointer;
            }
            .cancel-prj-btn {
                background-color: transparent;
                border: solid 1px $blue1;
                margin-right: 30px;
                color: $blue1;
                padding: 0 73px;
                width: auto;
                &:disabled {
                    background-color: transparent !important;
                    border: solid 1px $blue1 !important;
                    color: $blue1;
                    cursor: not-allowed;
                }
            }
            .create-prj-btn {
                background-color: $blue1;
                color: $white1;
                padding: 0 43px;
                width: auto;
                &:disabled {
                    background: $grey4;
                    color: $white1;
                    cursor: not-allowed;
                }
            }
        }
    }
}
