@import "../../../assets/scss/colors.scss";

.add-measurment-form-holder {
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  .project-view-form-container {
    width: 100%;
    flex-direction: column;
    .d-flex {
      width: 100%;
    }
    .title-and-back-btn {
      font-family: BookSizeFont;
      color: $black1;
      display: flex;
      img,
      svg {
        cursor: pointer;
      }
      .title-value {
        margin-left: 14px;
        margin-right: 18px;
        font-size: 32px;
        line-height: 38px;
      }
    }
    .page-desc-sec {
      font-size: 22px;
      line-height: 26px;
      margin-top: 6px;
      margin-left: 39px;
    }
  }
  .project-add-measurment-section {
    width: 100%;
    margin-top: 102px;
    margin-bottom: 48px;
    display: flex;
    .left-section-holder {
      width: 70%;
      .measurment-context {
        .heading-holder {
          margin-bottom: 32px;
          flex-direction: column;
          .heading-title {
            font-size: 28px;
            line-height: 34px;
            color: $black1;
            font-family: BookSizeFont;
          }
          .heading-title-content {            
            font-family: LightSizeFont;
            font-size: 16px;
            line-height: 19px;
            color: $black1;
            opacity: 0.8;
          }
          label {
            cursor: pointer;
            background-color: $blue1;
            color: $white1;
            width: auto;
            font-size: 16px;
            line-height: 19px;
            padding: 16px 45px;
            border: none;
            border-radius: 5px;
            font-family: BookSizeFont;
            &.disabled {
              background-color: $grey4;
              cursor: not-allowed;
            }
          }
          #add-measurment-file {
            opacity: 0;
            position: absolute;
            z-index: -1;
          }
          .add-measure-btn {
            color: $white1;
            font-size: 22px;
            line-height: 26px;
            background-color: $blue1;
            cursor: pointer;
            &:disabled {
              cursor: not-allowed;
              background-color: $grey4;
            }
          }
        }
        .add-measurment-form-div {
          flex-direction: column;
          .input-holder-d-flex {
            label:first-child {
              margin-left: 0px;
            }
            
            display: flex;
            margin-bottom: 45px;
            &.upld-msrmnt-main-div {
              display: flex;
              flex-direction: column;
             }
            .upld-msrmnt-main-label {
              font-family: BookSizeFont;
              font-size: 16px;
              line-height: 19px;
              color: $black8;
            }
            .raptor-label-input,
            .raptor-label-textarea {
              font-size: 16px;
            }
            .raptor-label-textarea {
              textarea {
                width: 713px;
              }
            }
            .raptor-select-input-container {
              margin: 0 auto 17px auto;
              padding: 0px;
              .raptor-select-input-label {
                font-size: 16px;
              }
            }
          }

          label.input-type-label-tag {
            cursor: pointer;
            background-color: $blue1;
            color: $white1;
            width: auto;
            font-size: 16px;
            line-height: 19px;
            padding: 16px 45px;
            border: none;
            border-radius: 5px;
            font-family: BookSizeFont;
          }
          input[id^="upload-measurment-file-"] {
            opacity: 0;
            position: absolute;
            z-index: -1;
          }
          .add-msr-act-btn-holder {
            margin-bottom: 50px;
            button {
              font-size: 16px;
              background-color: transparent;
              border: solid 1px $blue1;
              color: $blue1;
              margin-right: 33px;
              &:disabled {
                color: $white1;
              }
            }
          }
        }
      }
    }
    .project-thickness-input {
      .raptor-label-input {
        margin: 0px;
        input {
          margin: 0px auto;
          width: 67px;
          height: 35px;
          padding: 0px 5px;
          font-size: 20px;
          line-height: 24px;
          text-align: right;
        }
      }
    }
    .measurment-section-card {
      background: $white1;
      border: 2px solid $grey6;
      border-radius: 2px;
    }
    .measurment-map-card {
      width: 30%;
      padding: 0 20px;
    }
  }
}
