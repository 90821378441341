@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.raptor-select-input-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    label {
        font-size: 19px;
        margin-bottom: 15px;
        font-family: BookSizeFont;
    }

    .waywize-react-select.__control.waywize-react-select.__control--is-focused.waywize-react-select.__control--menu-is-open {
        border: 2px solid $blue5;
        outline-color: $blue5;
    }
    .waywize-react-select.__control {
        height: 60px;
        width: 383px;
        padding: 0 20px;
        border-radius: 4px;
        outline: none;
        border: 2px solid $grey2;
        box-shadow: none;
        &:focus,
        &:active {
            border: 2px solid $blue1;
        }

        .waywize-react-select.__value-container,
        .waywize-react-select.__input-container,
        .waywize-react-select.__single-value {
            font-family: BookSizeFont;
            font-size: 19px;
            color: $black1;
        }
        .waywize-react-select.__value-container {
            padding: 0px;
            .waywize-react-select.__placeholder {
                margin: 0px;
                color: $black1;
            }
            .waywize-react-select.__single-value {
                margin: 0px;
            }
        }
        .waywize-react-select.__indicators {
            cursor: pointer;
            .caret-down {
                transform: rotate(180deg);
                path {
                    fill: $black1;
                }
            }
            .waywize-react-select.__indicator-separator {
                display: none;
            }
        }
    }

    &.error_border {
        input, .waywize-react-select.__control {
            border: solid 1px $orange1;
        }
        .err-msg-span {
            font-family: BookSizeFont;
            margin-top: 12px;
            font-size: 16px;
            color: $orange1;
        }
    }

    .waywize-react-select.__menu {

        .waywize-react-select.__menu-list {

            padding: 0px;
            .waywize-react-select.__option {
                height: 50px;
                font-family: BookSizeFont;
                font-size: 16px;
                color: $black1;
                background-color: $white1;
                border-bottom: 1px solid $grey2;
                display: flex;
                cursor: pointer;
                align-items: center;

                &.__option--is-focused {
                    background: #eef0ff 0% 0% no-repeat padding-box;
                }
                &.__option--is-selected {
                    color: $black1;
                    background: #eef0ff 0% 0% no-repeat padding-box;
                }
            }
        }
        @include customScrollbar(".waywize-react-select.__menu-list");
    }
}
