@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.file-progress-info-holder {
  .progress-bar-title {
    font-family: BookSizeFont;
    font-size: 16px;
    line-height: 19px;
    color: $black1;
    margin: 8px 0 18px;
    span {
      color: $grey2;
      margin-left: 10px;
      &.dlt-fl {
        cursor: pointer;
      }
    }
  }
  .uploading-label {
    font-size: 10px;
    line-height: 12px;
    color: $green1;
    font-family: LightSizeFont;
    margin-top: 12px;
  }
  .uploading-progress-bar-holder {
    position: relative;
    div[id^="uploading-progress-val-div"] {
      font-family: BookSizeFont;
      text-align: right;
      position: absolute;
      right: 0;
      top: -4px;
      font-size: 14px;
      line-height: 17px;
      color: $grey3;
    }

    #uploading-progress-holder {
      width: calc(100% - 50px);
      background-color: $grey3;
      border-radius: 5px;
      div[id^="uploading-progress-bar-div"] {
        max-width: 1%;
        height: 5px;
        font-size: 17px;
        background-color: $green1;
        text-align: center;
        line-height: 28px;
        color: $white1;
        border-radius: 5px;
        @include transitionVal(max-width 0.5s ease-out);
      }
    }
  }
}
