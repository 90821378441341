@import "../../assets/scss/dynamic.scss";
@import "../../assets/scss/colors.scss";
@import "../../assets/scss/screen.scss";

.sidebar-container {
    height: 100%;
    width: 100px;
    background: $blue1;
    position: fixed;
    top: 72px;
    zoom: 0.8;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);

    .sidebar-menu-item {
        width: calc(100% - 4px);
        margin: 0 auto;
        border-radius: 5px;
        font-family: BookSizeFont;
        background-color: transparent;
        text-align: center;
        a {
            text-decoration: none;
            color: $black1;
            font-size: 16px;
            svg {
                margin-top: 24px;
            }
        }
        .menu-item-title {
            margin-top: 12px;
            margin-bottom: 20px;
            font-size: 15px;
        }
        &.active {
            background-color: $white5;
        }
    }
}
