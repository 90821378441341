@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.calculation-context {
  .brdr-btm-ln {
    display: flex;
    overflow-y: scroll;
    border-bottom: 1px solid #eeeeee;
    @media screen and (min-width: 992px) and (max-width:1742px) {
      display: grid;
    }
  }
  .tile-info-cont {
    padding-bottom: 40px;
  }
  .tile-title-label {
    font-size: 20px;
    line-height: 24px;
    color: $black1;
    opacity: 0.8;
    margin-bottom: 10px;
    font-family: BookSizeFont;
  }
  .tile-list-holder {
    display: flex;
    opacity: 0.8;
    border-radius: 10px;
    background-color: $grey6;
  }

  .select-box-holder {
    margin-top: 40px;
    .raptor-select-input-label {
      font-size: 14px;
      line-height: 17px;
    }
    .calc-select-box {
      height: 38px;
      width: 270px;
      .waywize-react-select.calculation__placeholder {
        color: $grey2 !important;
        font-size: 14px;
        line-height: 17px;
      }

      .waywize-react-select.calculation__indicators {
        cursor: pointer;
        .caret-down {
          transform: rotate(180deg);
          path {
            fill: $black1;
          }
        }
        .waywize-react-select.calculation__indicator-separator {
          display: none;
        }
      }
    }
  }

  .calc-detail-sec {
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    .calc-name-card {
      margin-right: 36px;
      width: 260px;
      height: 95px;
      color: $black1;
      border-radius: 10px;
      background-color: $blue4;
      font-size: 20px;
      line-height: 24px;
      font-family: BoldSizeFont;
    }
    .calc-val-label-card {
      margin-right: 140px;
      height: 95px;
      padding: 17px 20px;
      display: flex;
      flex-direction: column;
      align-items: self-end;
      justify-content: space-between;
      @media screen and (min-width: 992px) and (max-width:1742px) {
        margin-right: auto;
      }
      .cal-value {
        font-family: BoldSizeFont;
        font-weight: 350;
        font-size: 18px;
        line-height: 22px;
      }
      .cal-label {
        font-weight: 325;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        font-family: BookSizeFont;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .calc-heading-holder {
    margin-bottom: 10px;
    margin-top: 35px;
    .heading-title {
      font-size: 20px;
      line-height: 24px;
      color: $black1;
      font-family: BookSizeFont;
    }
    .result_rate_indicator {
      margin-top: 20px;
      padding: 0 10px;
      .result_dot {
        min-height: 15px;
        min-width: 15px;
        border-radius: 50%;
        &.satisfied {
          background-color: $green7;
        }
        &.need_investigation {
          background-color: $orange2;
        }
        &.failure {
          background-color: $red4
        }
      }
    }
    label {
      cursor: pointer;
      background-color: $blue1;
      color: $white1;
      width: auto;
      font-size: 16px;
      line-height: 19px;
      padding: 16px 45px;
      border: none;
      border-radius: 5px;
      font-family: BookSizeFont;
      &.disabled {
        background-color: $grey4;
        cursor: not-allowed;
      }
    }
    .download-all-file-btn {
      width: auto;
      font-weight: 325;
      font-size: 14px;
      line-height: 17px;
      color: $blue3;
      font-family: BookSizeFont;
      background-color: transparent;
      div {
        margin-top: 5px;
      }
    }
    .add-measure-btn {
      color: $white1;
      font-size: 22px;
      line-height: 26px;
      background-color: $blue1;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
        background-color: $grey4;
      }
    }
  }

  .calc-context-table {
    width: 100%;
    overflow-y: scroll;
    .graph-action-btn {
      display: grid;
      grid-template-columns: 50px 50px;
      .disabled-anchor {
        path {
          stroke: $grey4;
        }
      }
    }
    .rate_result-container {
      max-width: 117px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        border-radius: 50%;
      }
      .dot-btn {
        height: 28px;
        min-height: 28px;
        width: 28px;
        min-width: 28px;
        padding: 6px 7px;
        div {
          height: 15px;
          min-height: 15px;
          width: 15px;
          min-width: 15px;
        }
        .failure {
          background-color: $red4;
        }
        .need_investigation {
          background-color: $orange2;
        }
        .satisfied {
          background-color: $green7;
        }
        .grey-dot {
          background-color: $grey4;
        }
        &.active-state {
          border: solid 1px $black1;
        }
      }
    }
  }
  .file-title {
    font-size: 18px;
    line-height: 22px;
    color: $black1;
    font-family: LightSizeFont;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .file-time-stamp {
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
    font-family: LightSizeFont;
  }
  @include customScrollbar('.calc-context-table');
  @include customScrollbar('.brdr-btm-ln');
}
