@import "../../../assets/scss/colors.scss";

.map-network-widget-container {
    position: absolute;
    z-index: 1;
    bottom: 80px;
    right: 30px;
    zoom: 0.8;

    .network-widget-btn {
        color: $black1;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 5px;
        width: auto;
        height: 44px;
        background: $white1 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border: none;

        .network-btn-title {
            font-family: BookSizeFont;
            font-size: 15px;
            line-height: 15px;
            padding: 10px 19px;
        }
    }

    .map-network-opt-list {
        width: auto;
        height: auto;
        background: $white1 0% 0% no-repeat padding-box;
        box-shadow: 0px 9px 13px $grey4;
        border-radius: 5px;
        font-size: 12px;
        font-family: BookSizeFont;
        position: absolute;
        right: 0px;
        bottom: 55px;
        padding: 14px 14px 0 14px;

        .lengend-main-title {
            font-size: 18px;
            line-height: 22px;
            color: $black1;
        }

        .color-palette-holder {
            display: flex;
            border-radius: 5px;
            overflow: hidden;

            .color-palette-div {
                min-height: 20px;
                min-width: 3px;
            }
        }

        .min-max-rec-holder {
            .min-max-rec-opt {
                display: grid;
                grid-template-columns: 80px 100px 100px;
            }
        }
    }
}
