@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.edit-measur-form-container {
  .measur-input-form {
    width: 860px;
    overflow-y: scroll;
    .custom-popup-header-section {
      border: none;
    }
    .custom-popup-header-title {
      font-size: 18px;
      color: $blue1 !important;
    }
  }
  @include customScrollbar(".measur-input-form");

  button {
    background-color: $blue1;
    color: $white1;
    padding: 0 43px;
    width: auto;
    font-size: 16px;
    &:disabled {
      background: $grey4;
      color: $white1;
      cursor: not-allowed;
    }
  }
}
