@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.measurment-context {
  margin-top: -57px;
  .calculation-input-form {
    width: 860px;
    overflow-y: scroll;
    .custom-popup-header-section {
      border: none;
    }
    .custom-popup-wrapper {
      min-height: 580px;
      overflow-y: unset;
      height: 100% !important;
    }
    .calculation-form-context {
      padding: 0 30px;
      .calculation-form-context-info {
        margin-bottom: 50px;
      }
      .input-field {
        display: grid;
        grid-template-columns: 350px 400px;

        .title-label {
          width: 150px;
        }
        .yes-no-option-holder {
          display: flex;
          margin-right: 20px;
          .raptor-checkbox-input-container {
            margin: 5px;
            span.raptor-checkbox-checkmark {
              top: 20px;
            }
          }
        }
        .repo-length-input {
          input {
            width: 100px;
          }
        }
      }
      label.raptor-label-input {
        font-size: 16px;
        line-height: 19px;
        color: $black8;
        margin-left: 0px;
        input {
          width: auto;
        }
      }
      .calculation-form-m-sec-info {
        font-size: 16px;
        line-height: 19px;
        color: $black8;
        margin-top: 25px;
      }
      .sec-list {
        padding-top: 5px;
        .d-flex {
          margin-top: 25px;
          font-weight: 325;
          font-size: 16px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.5);

          .remove-btn {
            cursor: pointer;
            color: $blue1;
          }
        }
      }
      .action-btn-holder {
        text-align: end;
        margin-top: 50px;
        padding-bottom: 60px;
        button {
          padding: 19px 48px;
          width: auto;
          font-size: 16px;
          line-height: 19px;
          height: 55px;
        }
        .cancel-btn {
          border-radius: 5px;
          border: solid 1px $blue1;
          color: $blue1;
          background-color: $white1;
          margin-right: 20px;
        }
        .submit-btn {
          border: none;
          background-color: $blue1;
          color: $white1;
        }
      }
    }
  }
  .measurment-context-table {
    overflow-y: scroll;
    margin-bottom: 100px;
    table {
      max-height: 700px;
      // display: block;
      overflow: auto;
    }
    .raptor-select-input-container {
      &.calibration-options {
        .waywize-react-select.__menu {
          position: inherit;
          width: 280px;
        }
        .waywize-react-select.__control {
          height: 47px;
          width: 270px;
          padding: 9.8px;
        }
        .waywize-react-select.__value-container {
          font-size: 16px;
          line-height: 19px;
          text-align: left;
        }
        .waywize-react-select.__dropdown-indicator {
          padding: 0px;
        }
      }
    }
  }
  @include customScrollbar('.calculation-input-form');
  @include customScrollbar('.measurment-context-table');
  @include customScrollbar('.measurment-context-table table');

  .btn-holder {
    position: relative;
    top: -160px;
    width: 50%;
    transform: translate(100%, 0px);
    label {
      cursor: pointer;
      background-color: $blue1;
      color: $white1;
      width: auto;
      font-size: 16px;
      line-height: 19px;
      padding: 16px 45px;
      border: none;
      border-radius: 5px;
      font-family: BookSizeFont;
      &.disabled {
        background-color: $grey4;
        cursor: not-allowed;
      }
    }
    .add-measurment-file {
      color: $blue1;
      border: solid 1px $blue1;
      background-color: $white1;
    }
    .add-measurment-file, .calculate-btn {
      font-size: 22px;
      line-height: 26px;
    }
    .calculate-btn {
      color: $white1;
      background-color: $blue1;
      margin-left: 27px;
    }
    .add-measure-btn {
      color: $white1;
      font-size: 22px;
      line-height: 26px;
      background-color: $blue1;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
        background-color: $grey4;
      }
    }
  }
  .file-title {
    font-size: 18px;
    line-height: 22px;
    color: $black1;
    font-family: LightSizeFont;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .file-time-stamp {
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.7);
    font-family: LightSizeFont;
  }
}
