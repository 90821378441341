@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/dynamic.scss";

.calculation-context {
    .deflection-chart-popup {
        width: 100%;
        height: 75vh;
        overflow-y: scroll;
        overflow-x: hidden;
        .custom-popup-wrapper {
            max-height: 87vh;
            height: 100%;
        }
        .custom-popup-header-section {
            height: auto;
            border: none;
        }
        .deflection-chart-holder {
            width: 100%;
            height: 480px;
            max-width: 1200px;
        }
        .download-chart-btn-holder {
            display: flex;
            max-width: 1200px;
            width: 100%;
            justify-content: flex-end;
            .download-chart-btn {
                cursor: pointer;
            }
        }
      }
      
  @include customScrollbar('.deflection-chart-popup');
}
.def-reset-chart-btn {
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    color: $white1;
    background-color: $blue1;
    padding: 5px;
}