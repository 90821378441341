@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.measurment-tab-container {
    font-size: 18px;
    line-height: 22px;
    .image-holder {
        width: $tabDivWidth;
        background: $white1;
    }
    .calculation-info-sec {
        font-size: 16px;
        line-height: 19px;
        color: $black1;
        padding: 20px;
        .info-title,
        span {
            font-family: BoldSizeFont;
        }
        .info-title {
            padding-bottom: 10px;
        }
        .info-value {
            display: grid;
            grid-template-columns: 150px 150px;
            font-family: LightSizeFont;
            padding: 8px 0px;
        }
    }
    .tab-btn-holder {
        padding: 20px;
        width: $tabDivWidth;
        background: $white1;
        .btn-holder {
            flex-direction: column;
            cursor: pointer;
            .tab-icon {
                height: 52px;
                width: 52px;
                border-radius: 50%;
                background-color: $white1;
                border: 1px solid $blue1;
                &.active {
                    border: 1px solid $white1;
                    background-color: $blue1;
                }
            }
            .btn-title {
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: $black1;
                margin-top: 7px;
                font-family: LightSizeFont;
            }
        }
    }
    .select-multi-measur {
        color: $black1;
        padding: 20px;
        font-family: LightSizeFont;
        width: $tabDivWidth;
        background: $white1;
        position: relative;
        .toogle-full-view-btn {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            bottom: 0;
            right: 15px;
            cursor: pointer;
            svg {
                transform: rotate(90deg);
            }
            &.active {
                svg {
                    transform: rotate(-90deg);
                }   
            }
        }
        .select-multi-measur-title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
        }
    }
    .multi-options {
        padding: 25px 15px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 30px;
        background: $white1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        .measurment-context {
            margin-top: unset;
        }
        .measurment-context .measurment-context-table {
            margin-bottom: unset;
        }
        .no-measurment-found {
            flex-direction: column;
        }
        .raptor-checkbox-input-container {
            width: auto;
            margin-bottom: 15px;
            label {
                height: 100%;
            }
            .checkbox-label {
                margin-left: 30px;
                font-size: 15px;
                line-height: 15px;
                height: 100%;
                display: flex;
                align-items: center;
                font-family: LightSizeFont;
            }
        }
    }
    .select-visual-parameter {
        width: $tabDivWidth;
        background-color: $white1;
        .raptor-select-input-container {
            padding-top: 0px;
        }
    }
    .show-events-checkbox-container {
        display: flex;
        margin-bottom: 20px;
        .raptor-checkbox-input-container {
            margin-right: 10px;
            margin-left: 20px;
        }
    }
    .select-visual-hr {
        min-width: calc(100% - 40px);
        height: 1px;
        background: #DCD5D5;
        min-width: calc(100% - 40px);
        margin: 0 auto;
    }
}
