.spin-loader-container {
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
}