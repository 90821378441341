@import "../../../assets/scss/colors.scss";

.raptor-checkbox-input-container {
    height: 21px;
    width: 21px;
    margin: 0 auto;

    .raptor-checkbox-input-label {
        display: block;
        position: relative;
        cursor: pointer;
        font-family: BookSizeFont;
        font-size: 12px;
    }

    /* Hide the browser's default checkbox */
    .raptor-checkbox-input-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .raptor-checkbox-checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 21px;
        width: 21px;
        background-color: $black3;
        border: 1px solid $black3;
        border-radius: 3px;
    }

    /* When the checkbox is checked, add a blue background */
    .raptor-checkbox-input-label input:checked ~ .raptor-checkbox-checkmark {
        background-color: $blue1;
        border: 1px solid $blue1;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .raptor-checkbox-checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .raptor-checkbox-input-label input:checked ~ .raptor-checkbox-checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .raptor-checkbox-input-label .raptor-checkbox-checkmark:after {
        left: 7px;
        top: 3px;
        width: 6px;
        height: 11px;
        border: solid $white1;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
