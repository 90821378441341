@import '../../../assets/scss/colors.scss';

.raptor-button {
    border: none;
    border-radius: 5px;
    font-size: 24px;
    color: $black1;
    width: 230px;
    height: 56px;
    margin: 0 auto;
    background-color: $orange1;
}