@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.basic-static-form-container {
  .calculation-input-form {
    width: 100%;
    height: 72vh;
    overflow-y: scroll;
    .custom-popup-header-section {
      border: none;
    }
    .custom-popup-header-title {
      font-size: 18px;
      color: $blue1 !important;
    }
    .custom-popup-wrapper {
      height: 720px;
      overflow: unset;
    }
  }
  @include customScrollbar('.calculation-input-form');
}
