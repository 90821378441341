@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.raptor-label-input {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 19px;
    margin: 0 auto 17px auto;
    font-family: BookSizeFont;
    &.error_border {
        input {
            border: solid 1px $orange1;
        }
        .err-msg-span {
            margin-top: 12px;
            font-size: 16px;
            color: $orange1;
        }
    }

    .validate-msg-span {
        color: $orange1;
        margin-top: 5px;
    }

    input[type="date"] {
        position: relative;
    }

    input[type="date"]:after {
        content: url("./Calendar.svg");
        position: absolute;
        top: 15px;
        bottom: 0px;
        right: 22px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
    }

    input {
        width: 380px;
        height: 60px;
        padding: 18px 20px;
        background: $white1 0% 0% no-repeat padding-box;
        border-radius: 5px;
        margin-top: 15px;
        color: $black1;
        border: 2px solid $grey2;
        &:focus-visible,
        &:focus,
        &:active {
            border: 2px solid $blue1;
            outline-color: $blue1;
        }
        &:disabled {
            background: $grey6;
        }
    }

    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-family: BookSizeFont;
    }
    input::-moz-placeholder {
        /* Firefox 19+ */
        font-family: BookSizeFont;
    }
    input:-ms-input-placeholder {
        /* IE 10+ */
        font-family: BookSizeFont;
    }
    input:-moz-placeholder {
        /* Firefox 18- */
        font-family: BookSizeFont;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
}
