@import "../../../assets/scss/colors.scss";

.prjct-map-and-form-holder {
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  .project-view-form-container {
    width: 100%;
    flex-direction: column;
    .d-flex {
      width: 100%;
    }
    .title-and-back-btn {
      font-family: BookSizeFont;
      color: $black1;
      display: flex;
      img,
      svg {
        cursor: pointer;
      }
      .title-value {
        margin-left: 14px;
        margin-right: 18px;
        font-size: 32px;
        line-height: 38px;
      }
    }
    .page-desc-sec {
      font-size: 22px;
      line-height: 26px;
      margin-top: 6px;
      margin-left: 39px;
    }
  }
  .project-view-section {
    width: 100%;
    margin-top: 48px;
    margin-bottom: 48px;
    display: flex;
    .left-section-holder {
      width: 100%;
      .measurment-context {
        .heading-holder {
          margin-bottom: 10px;
          .heading-title {
            font-size: 28px;
            line-height: 34px;
            color: $black1;
            font-family: BookSizeFont;
          }
          label {
            cursor: pointer;
            background-color: $blue1;
            color: $white1;
            width: auto;
            font-size: 16px;
            line-height: 19px;
            padding: 16px 45px;
            border: none;
            border-radius: 5px;
            font-family: BookSizeFont;
            &.disabled {
              background-color: $grey4;
              cursor: not-allowed;
            }
          }
          .add-measurment-file {
            font-size: 22px;
            line-height: 26px;
            color: $white1;
            background-color: $blue1;
          }
          .add-measure-btn {
            color: $white1;
            font-size: 22px;
            line-height: 26px;
            background-color: $blue1;
            cursor: pointer;
            &:disabled {
              cursor: not-allowed;
              background-color: $grey4;
            }
          }
        }
        .file-title {
          font-size: 18px;
          line-height: 22px;
          color: $black1;
          font-family: LightSizeFont;
        }
        .file-time-stamp {
          font-size: 16px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.7);
          font-family: LightSizeFont;
        }
      }
    }
    .project-thickness-input {
      .raptor-label-input {
        margin: 0px;
        input {
          margin: 0px auto;
          width: 67px;
          height: 35px;
          padding: 0px 5px;
          font-size: 20px;
          line-height: 24px;
          text-align: right;
        }
      }
    }
    .measurment-section-card {
      background: $white1;
      border: 2px solid $grey6;
      border-radius: 2px;
      .no-measurment-found {
        flex-direction: column;
        padding: 100px;
        .no-measurment-title,
        .no-measurment-message {
          font-family: BookSizeFont;
          color: $black1;
          opacity: 0.8;
        }
        .no-measurment-title {
          font-size: 32px;
          line-height: 38px;
          margin-top: 30px;
          margin-bottom: 15px;
        }
        .no-measurment-message {
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 55px;
        }
        label {
          cursor: pointer;
          background-color: $blue1;
          color: $white1;
          width: auto;
          font-size: 16px;
          line-height: 19px;
          padding: 16px 45px;
          border: none;
          border-radius: 5px;
          font-family: BookSizeFont;
        }
        #upload-measurment-file {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
  }
}
