@import "../../assets/scss/colors.scss";

.terms-container {
    .terms-header {
        position: fixed;
        width: 100%;
        display: flex;
        align-items: center;
        height: 97px;
        cursor: pointer;
        padding-left: 48px;
        background: $white1 0% 0% no-repeat padding-box;
        box-shadow: 0px 7px 10px $grey1;
        top: 0;
    }
    .terms-content {
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto;

        background: $white2 0% 0% no-repeat padding-box;
        margin-top: 97px;
        padding-bottom: 97px;

        .p-heading {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
            text-align: center;
            span {
                font-size: 13px;
                line-height: 107%;
                font-family: "Verdana", sans-serif;
            }
        }
        .p-heading2 {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
            .date-span {
                font-size: 13px;
                line-height: 107%;
                font-family: "Verdana", sans-serif;
            }
            .date-value {
                font-size: 13px;
                line-height: 107%;
                font-family: "Verdana", sans-serif;
            }
        }
        .heading-div {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
            ol {
                margin-bottom: 0cm;
                list-style-type: decimal;
                margin-left: 0cmundefined;
                li {
                    margin-top: 0cm;
                    margin-right: 0cm;
                    margin-bottom: 8pt;
                    margin-left: 0cm;
                    line-height: 107%;
                    font-size: 15px;
                    font-family: "Calibri", sans-serif;
                    .sp1 {
                        line-height: 107%;
                        font-family: "Verdana", sans-serif;
                        font-size: 13px;
                    }
                    .sp2 {
                        line-height: 107%;
                        font-family: "Verdana", sans-serif;
                        font-size: 13px;
                    }
                }
            }
        }
        .p-cls {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 36pt;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
        }
        .ol-cls {
            list-style-type: lower-alpha;
            li {
                span {
                    line-height: 107%;
                    font-family: "Verdana", sans-serif;
                    font-size: 13px;
                }
            }
        }
        .p-cls22 {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 18pt;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
            span {
                font-size: 13px;
                line-height: 107%;
                font-family: "Verdana", sans-serif;
            }
        }
        .cmn-style {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
        }
        .li-span {
            line-height: 106%;
            font-family: "Verdana", sans-serif;
            font-size: 13px;
        }
        .p-span-style {
            font-size: 13px;
            line-height: 107%;
            font-family: "Verdana", sans-serif;
        }
        .u-span-style {
            line-height: 107%;
            font-family: "Verdana", sans-serif;
            font-size: 13px;
        }
        .p-div-li {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
        }
        .ol-cmn {
            margin-bottom: 0cm;
            list-style-type: undefined;
            margin-left: 0cmundefined;
        }
        .fs-13 {
            font-size: 13px;
        }
        .p-abc {
            margin-top: 12pt;
            margin-right: 0cm;
            margin-bottom: 8pt;
            margin-left: 0cm;
            line-height: 107%;
            font-size: 15px;
            font-family: "Calibri", sans-serif;
            text-align: justify;
        }
    }
    .terms-section {
        padding-top: 30px;
        width: 75%;
        margin: 0 auto;
    }
    // font-family: BookSizeFont;
    // color: $grey3;
    // font-size: 20px;
}
