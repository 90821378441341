@import "../../../../assets/scss/colors.scss";

.tile-card-container {
    width: 150px;
    height: 116px;
    cursor: pointer;
    color: $black1;
    padding: 17px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.active {
        background-color: $blue1;
        color: $white1;
    }
    .icon-count-holder {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        align-items: center;
        font-family: BookSizeFont;
        .count-holder {
            font-weight: 325;
            font-size: 32px;
            line-height: 38px;
        }
    }
}
