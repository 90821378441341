@import "../../assets/scss/colors.scss";
@import "../../assets/scss/screen.scss";

#app {
    background: url("./../../assets/images/welcome-bg.svg") no-repeat;
    background-color: rgba(0, 0, 0, 0.8);
    background-size: cover;

    .welcome-container {
        color: $green1;
        text-align: center;
        min-height: 100vh;
        font-family: BookSizeFont;

        .log-in-button {
            color: $white2;
            background: $green1;
            border-radius: 36px;
            font-size: 24px;
            cursor: pointer;
            text-decoration: none;
            padding: 15px 64px;
            transition: 0.5s;
            border: none;
            &:hover {
                color: $white1;
                background: $green6;
            }
        }

        .welcome-h1 {
            margin-top: 25vh;
            color: $white1;
            position: relative;
            font-size: 64px;
            line-height: 77px;
            span {
                color: $blue1;
            }
            @include mobile {
                font-size: 35px;
                margin-top: 10vh;
            }
        }
        .welcome-msg {
            width: 100%;
            max-width: 744px;
            height: 58px;
            color: $white1;
            margin-bottom: 54px;
            font-size: 24px;
            letter-spacing: 0px;
            
            @include mobile {
                font-size: 20px;
            }
        }

        .raptor-logo-holder {
            width: 103px;
            height: 74px;
            background: #FFFFFF;
            border-radius: 5px;
            margin-top: 45px;
            margin-left: 50px;

            .raptor-logo {
                opacity: 1;
            }
        }

        .welcome-info-holder {
            text-align: left;
            margin-left: 168px;
            .welcome-login-btn {
                position: relative;
                z-index: 1;
            }
        }
    }
    .raptor-copyright {
        a {
            color: $white1;
        }
    }
}
