@import "../../assets/scss/colors.scss";

.raptor-copyright {
    width: 100%;
    color: $white1;
    font-family: 'BookSizeFont';
    font-size: 16px;
    position: fixed;
    bottom: 0px;
    padding-top: 20px;
    padding-bottom: 40px;
    .term-service {
        color: $black1;
        text-decoration: none;
        &:hover {
            color: $blue1;
        }
    }
}
