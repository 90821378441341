@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/dynamic.scss";

.spin-loader2-container {
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1051;

    .spin-loader2-progress-container {
        padding: 60px;
        max-width: 850px;
        border-radius: 5px;
        background-color: $white1;
        .spin-loader2-title {
            color: $black1;
            font-size: 28px;
            line-height: 34px;
            font-family: BookSizeFont;
        }
        .spin-loader2-progress-bar-text {
            font-size: 22px;
            line-height: 26px;
            font-family: LightSizeFont;
            margin: 32px auto;
            text-align: left;
        }

        #spin-loader2-progress-holder {
            width: 100%;
            background-color: $grey3;
        }

        #spin-loader2-progress-bar {
            max-width: 1%;
            height: 56px;
            font-size: 26px;
            line-height: 31px;
            background-color: $blue1;
            text-align: center;
            color: $white1;
            font-family: BoldSizeFont;
            display: grid;
            align-items: center;
            @include transitionVal(max-width 0.5s ease-out);
        }
    }
}
