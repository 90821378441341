$black1: #000000;
$black2: #141313;
$black3: #D9D9D9;
$black4: #161616;
$black5: #131212;
$black6: #2C2C2C;
$black7: #4A4A4A;
$black8: #000D1A;
$white1: #ffffff;
$white2: #fbfbff;
$white3: #FBFBFB;
$white4: #C7BABA;
$white5: #FFFBFB;
$white6: #F5F5F5;
$blue1: #009DF0;
$blue2: #33B1F3;
$blue3: #66C4F6;
$blue4: #99D8F9;
$blue5: #CCEBFC;
$blue6: #E6F5FD;
$blue7: #006FBD;
$green1: #125A40;
$green2: #417B66;
$green4: #A0BDB2;
$green6: #E7EEEC;
$green7: #1E8542;
$red1: #FF313A;
$red2: #FFEBEC;
$red3: #D73027;
$red4: #DF2323;
$grey1: #444B53;
$grey2: #7E878F;
$grey3: #A1A8AE;
$grey4: #BABDC1;
$grey5: #DADCDD;
$grey6: #E5E6E7;
$grey7: #EEF0F2;
$orange1: #FF8854;
$orange2: #FC9532;
$orange3: #FCAF65;
$orange4: #FECA98;