@import '../../../assets/scss/colors.scss';

.raptor-table-pagination {
    padding: 5px 0 30px 0;
    margin: 0 auto 80px;
    display: flex;
    align-items: center;
    width: 100%;
    button {
        outline: none;
        border-radius: 3px;
    }
    .pagi-prev-btn,
    .pagi-next-btn {
        height: 30px;
        width: 32px;
        outline: none;
        border: none;
        background: $white1 0% 0% no-repeat padding-box;
        &:disabled {
            background: $white1 0% 0% no-repeat padding-box !important;
        }
    }
    .page-nmbr-btn {
        background-color: $white1;
        color: $black4;
        height: 37px;
        width: 37px;
        font-size: 14px;
        border-radius: 5px;
        border: none;
        outline: none;
        margin: 0 5px;
        cursor: pointer;

        &.active {
            background-color: $blue1;
            color: $white1;
        }
    }
    .page-record-counting {
        color: $black4;
        font-size: 13px;
        line-height: 17px;
    }
    select {
        height: 30px;
    }
    .go-to-page-sec {
        .raptor-label-input {
            margin: 0px;
            input {
                width: 67px;
                height: 29px;
                padding: 0px 10px;
                margin: 0px;
                background: $white1 0% 0% no-repeat padding-box !important;
                border: 1px solid $blue3 !important;
                border-radius: 3px;
            }
        }
    }
}