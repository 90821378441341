@import "../../../assets/scss/colors.scss";

.no-calb-found {
  #upload-calibration-file-2 {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  label.upload_calibration_file_2 {
    font-size: 22px;
    line-height: 25px;
    color: $blue1;
    background-color: $white1;
    border: solid 1px $blue1;
    min-width: 305px;
    width: 100%;
    max-width: 380px;
    border-radius: 5px;
    padding: 15px 20px;
    text-align: center;
    cursor: pointer;
  }
}
.calibration-files-header-container {
  display: flex;
  position: relative;
  top: -85px;
  width: 50%;
  transform: translate(100%, 0px);

  .delete-calb-file {
    font-size: 22px;
    line-height: 25px;
    color: $blue1;
    background-color: $white1;
    border: solid 1px $blue1;
    width: 100%;
    max-width: 380px;
    border-radius: 5px;
    padding: 15px 20px;
    text-align: center;
    cursor: pointer;
    margin-right: 20px;
  }
  
  #upload-calibration-file {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  label.upload_calibration_file {
    font-size: 22px;
    line-height: 25px;
    color: $blue1;
    background-color: $white1;
    border: solid 1px $blue1;
    min-width: 305px;
    width: 100%;
    max-width: 380px;
    border-radius: 5px;
    padding: 15px 20px;
    text-align: center;
    cursor: pointer;
  }
}

.calibration-files-context {
  margin-top: -57px;
  .h3-fetching-text {
    font-family: MediumSizeFont;
    font-size: 20px;
    color: $black1;
  }
}
