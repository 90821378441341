@import "../../assets/scss/colors.scss";

.about-us-container {
    .about-us-header,
    .about-us-footer {
        position: fixed;
        width: 100%;
        display: flex;
        align-items: center;
        height: 97px;
    }
    .about-us-header {
        cursor: pointer;
        padding-left: 48px;
        background: $white1 0% 0% no-repeat padding-box;
        box-shadow: 0px 7px 10px $grey1;
        top: 0;
    }
    .about-us-footer {
        bottom: 0;
        color: $grey2;
        justify-content: center;
        background: $white2;
        font-size: 14px;
        a {
            text-decoration: none;
            margin-left: 5px;
            cursor: pointer;
            color: $grey2;
        }
    }
    .about-us-content {
        background: $white2 0% 0% no-repeat padding-box;
        height: 100vh;
        margin-top: 97px;
        overflow: scroll;
        padding-bottom: 97px;
    }
    .about-us-heading {
        width: 237px;
        height: 36px;
        font-size: 30px;
        text-align: left;
        letter-spacing: 0px;
        color: $black1;
        font-weight: 700;
        margin-top: 50px;
        margin-bottom: 27px;
    }
    .about-us-section {
        padding-top: 30px;
        width: 75%;
        margin: 0 auto;

        .version-welcome-msg {
            font-size: 17px;
            border-top: solid 1px $grey3;
            border-bottom: solid 1px $grey3;
            margin-bottom: 27px;
            padding: 9px 0;
            font-family: BookSizeFont;
            color: $grey3;
        }
    }
    .about-us-desc {
        font-family: BookSizeFont;
        color: $grey3;
        font-size: 20px;
        ul {
            list-style-type: auto;
            padding-left: 20px;
            line-height: 40px;
            li {
                font-size: 20px;
            }
        }
    }
}
