@import "../../../assets/scss/colors.scss";

.user-associate-form-context {
    button {
        background-color: $blue1;
        color: $white1;
    }

    hr {
        background-color: $grey1;
        margin-top: 20px;
    }
    .associated-sec {
        padding: 20px;
    }
}
.prj-btn-holder {
    display: flex;
    align-items: center;

    button {
        font-size: 16px;
        font-family: "BookSizeFont";
    }

    .dlt-prj-btn {
        background-color: transparent;
        border: solid 1px $blue1;
        margin-right: 30px;
        color: $blue1;
        padding: 0 17px;
        width: auto;
    }
    .create-prj-btn {
        background-color: $blue1;
        color: $white1;
        padding: 0 43px;
        width: auto;
    }
}

.project-context {
    .h3-fetching-text {
        font-family: MediumSizeFont;
        font-size: 20px;
        color: $black1;
    }
}
