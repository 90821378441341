@import "../../../../assets/scss/colors.scss";

.common-multi-select-filter {
    text-align: initial;

    .waywize-react-select.__placeholder {
        color: $grey2 !important;
        font-size: 14px;
        line-height: 17px;
      }
      .raptor-select-input-container {
        margin-top: 15px;
        padding-top: 1px;
      }
      .raptor-select-input-container .waywize-react-select.__control {
        min-height: 40px;
        width: 200px;
        height: auto;
      }
}