@use "./index" as *;

:export {
    // Define Colors
    black1: $black1;
    black2: $black2;
    black3: $black3;
    black4: $black4;
    black5: $black5;
    black6: $black6;
    black7: $black7;
    black8: $black8;
    white1: $white1;
    white2: $white2;
    white3: $white3;
    white4: $white4;
    white5: $white5;
    white6: $white6;
    blue1: $blue1;
    blue2: $blue2;
    blue3: $blue3;
    blue4: $blue4;
    blue5: $blue5;
    blue6: $blue6;
    blue7: $blue7;
    green1: $green1;
    green2: $green2;
    green4: $green4;
    green6: $green6;
    green7: $green7;
    red1: $red1;
    red2: $red2;
    red3: $red3;
    red4: $red4;
    grey1: $grey1;
    grey2: $grey2;
    grey3: $grey3;
    grey4: $grey4;
    grey5: $grey5;
    grey6: $grey6;
    grey7: $grey7;
    orange1: $orange1;
    orange2: $orange2;
    orange3: $orange3;
    orange4: $orange4;

    // Define Screen Sizes
    mobile: $tablet-width-min;
    tablet: $desktop-width-min;

    zoomLevel: $zoomLevel;
    tabDivWidth: $tabDivWidth;
}

$sides: (top, bottom, left, right);

@for $i from 0 through 99 {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$i} {
            margin-#{$side}: #{$i}px;
        }
        .p#{str-slice($side, 0, 1)}-#{$i} {
            padding-#{$side}: #{$i}px;
        }
    }
}

.o-auto {
    overflow: auto;
}

.d-flex {
    display: flex;
}

.ai-center {
    align-items: center;
}

.center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.between-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.evenly-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.around-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.end-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.f-column {
    flex-direction: column;
}

.f-right {
    float: right;
}

.f-left {
    float: left;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

button:focus {
    outline: none !important;
}

.b-none {
    border: none !important;
}

.c-pointer {
    cursor: pointer;
}

button:disabled {
    cursor: not-allowed;
    background-color: $grey2 !important;
    border: none !important;
    &:hover {
        background-color: $grey2 !important;
        border: none !important;
    }
}

input::placeholder {
    font-family: "MediumSizeFont";
}
input::-webkit-input-placeholder {
    font-family: "MediumSizeFont";
}
input::-moz-placeholder {
    font-family: "MediumSizeFont";
}
input:-ms-input-placeholder {
    font-family: "MediumSizeFont";
}
input:-moz-placeholder {
    font-family: "MediumSizeFont";
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: unset;
}
.table-hover>tbody>tr:hover>* {
    --bs-table-bg-state: unset;
}
.table-bordered>:not(caption)>*>* {
    background: transparent !important;
}